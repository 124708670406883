import * as React from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/Layout';
import Image from '../components/Image';

export default function About({
  data: {
    images: {nodes: images},
  },
}) {
  return (
    <Layout>
      <div className="lg:w-8/12 mx-auto text-dark py-32 px-8">
        <h1 className="text-6xl text-brand mb-8 font-bold">About this projects</h1>
        <h2 className="text-brand text-3xl font-bold my-4">The Workshop</h2>
        <p className="pb-4">
          Wash Your Hands is a project developed during the second-year workshop of the Master
          Degree in Communication Design of the Politecnico di Milano.
        </p>

        <p className="pb-4">
          The workshop focused on design for behaviour change and consisted in the realisation of
          six projects aimed at the prevention of COVID-19 spread.
        </p>

        <p className="pb-4">
          Followed by professors Jorge Frascara and Guillermina Noël, students developed projects
          that tried to bridge the gap between believing in health recomendations and acting upon
          them.
        </p>

        <h2 className="text-brand text-3xl font-bold my-4 ">The Project</h2>

        <p className="pb-4">
          Our project focused on hand washing, an everyday activity that may seem trivial but it’s
          rarely performed in an effective manner
        </p>

        <p className="pb-4">
          The first point of contact with the user is a set of stickers placed in the campus’s
          toilets. These stickers present colorful images of bacteria colonies in order to catch the
          public’s attention, who is then offered some facts about the health benefits of hand
          washing. The user can then scan a QR Code that links to this website, where they can
          download the stickers or leave comments on how to improve the campaign.
        </p>
        <div className="grid lg:grid-cols-2 gap-6">
          <div className="lg:col-span-2">
            <Image image={images[1]} />
          </div>
          <Image image={images[0]} />
          <Image image={images[2]} />
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  {
    images: allFile(filter: {relativePath: {regex: "/bagno*/"}}, sort: {fields: [relativePath]}) {
      nodes {
        relativePath
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`;
